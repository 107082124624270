*{
  margin: 0;
  padding: 0;
}

.wrapper{
  overflow-x: hidden;
  overflow-y: auto;    
}

.wrapper h1{
  margin-top: 30px;
  text-align: center;
}

.wrapper p{
  text-align: center;
  margin-top: 7px;
  font-weight: bold;
}

.wrapper .main{
  display: flex;
  margin-top: 20px;
  align-items: center;
}

.wrapper .view-container{
  width: 600px;    
  background-color: #e4e4e4;
  margin: 20px auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
}


tbody{
  margin-top: 25px;
}

